import React, { useEffect, useState } from 'react';
import presidentIcon from '../../../static/images/president.png';
import copyIcon from '../../../static/images/copy.svg';
import { Donate } from './Donate';
import { DONATION_ADDRESS } from '../../config';
import { copy } from '../../helpers/utils/copy';
import { DonationShape } from './DonationShape';
import { DonationProgress } from './DonationProgress';
import { Comunity } from './Comunity';
import { TextHeader } from './TextHeader';
import { isBrowser } from '../../helpers/utils/isBrowser';

export const Tablet = () => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);
  return (
    <div className="container flex firstSection tablet">
      <TextHeader />
      <div className="mtop50">
        <DonationProgress />
        <div className="mtop16">
          <DonationShape width="100%" />
        </div>
      </div>
      <div className="flex mtop72 justify-between">
        <div className="flex quoteWrap">
          <div className="quoteContainer">
            <div className="presidentQuote">
              “I need ammunition, not a ride”
            </div>
          </div>
          <div className="flex mtop20 mleft30 align-center">
            <img src={presidentIcon} className="presidentImg" alt="president" />
            <div className="flex flex-col mleft16">
              <span className="presidentName">Volodymyr Zelensky</span>
              <span className="presidentPosition">
                The President of Ukraine
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="rightPanel mtop80"
        style={
          isBrowser && window.innerHeight - scrollTop < 632 && scrollTop > 10
            ? { bottom: `24px`, position: 'fixed', zIndex: 10 }
            : {}
        }
      >
        <h3 className="gray80 fullwidth tacenter">Send XTZ to this address</h3>
        <div className="relative mtop20 inputWrapper">
          <input
            className="input fullwidth inputReadonly"
            defaultValue={DONATION_ADDRESS}
            readOnly
          />
          <button className="absolute copy" onClick={() => copy()}>
            <img src={copyIcon} alt="copy xtz address" />
          </button>
        </div>
        <div className="grayHr mtop44"></div>
        <div className="mtop32 panelInfo">
          Or just input the amount and confirm the transaction in your wallet:
        </div>
        <Donate />
      </div>
      <Comunity />
    </div>
  );
};
