import { useState, useCallback, useEffect } from "react";

import constate from "constate";

function useDonation() {
  const [donated, setDonated] = useState(0);

  const getBalance = useCallback(async () => {
    const res = await fetch(
      `https://donation-progress-api-rrrs5.ondigitalocean.app/progress`
    ).then((x) => x.json());
    setDonated(res);
  }, [setDonated]);

  useEffect(() => getBalance(), [getBalance]);

  return {
    donated,
  };
}

export const [DonationProgressProvider, useDonationProgress] =
  constate(useDonation);
