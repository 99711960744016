import { AccountModal } from "../components/modals/AccountModal";
import { WalletModal } from "../components/modals/WalletModal";
import { ConnectModalsStateProvider } from "./use-connect-modals-state";

export const WalletWrapper = ({ children }) => {
  return (
    <ConnectModalsStateProvider>
      {children}
      <WalletModal />
      <AccountModal />
    </ConnectModalsStateProvider>
  );
};
