import React, { useEffect, useState } from 'react';
import presidentIcon from '../../../static/images/president.png';
import copyIcon from '../../../static/images/copy.svg';
import { Donate } from './Donate';
import { DONATION_ADDRESS } from '../../config';
import { copy } from '../../helpers/utils/copy';
import { DonationProgress } from './DonationProgress';
import { DonationShape } from './DonationShape';
import { Comunity } from './Comunity';
import { TextHeader } from './TextHeader';
import { isBrowser } from '../../helpers/utils/isBrowser';

export const Laptop = () => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);
  return (
    <div className="container flex firstSection laptop">
      <TextHeader />
      <div className="flex mtop72 justify-between">
        <div>
          <div className="quoteContainer">
            <div className="presidentQuote">
              “I need ammunition, not a ride”
            </div>
          </div>
          <div className="flex mtop20">
            <img src={presidentIcon} className="presidentImg" alt="president" />
            <div className="flex flex-col mleft16">
              <span className="presidentName">Volodymyr Zelensky</span>
              <span className="presidentPosition">
                The President of Ukraine
              </span>
            </div>
          </div>
        </div>
        <div>
          <DonationProgress />
          <div className="mtop16">
            <DonationShape width={460} />
          </div>
          <Comunity />
        </div>
      </div>
      <div
        className={'rightPanel mtop80'}
        style={
          isBrowser && window.innerHeight - scrollTop < 782 && scrollTop > 10
            ? { bottom: '24px', position: 'fixed' }
            : {}
        }
      >
        <div className="flex align-center">
          <h3 className="gray80 minw-283">Send XTZ to this address</h3>
          <div className="relative mleft40 inputWrapper fullwidth">
            <input
              className="input fullwidth inputReadonly"
              defaultValue={DONATION_ADDRESS}
              readOnly
            />
            <button className="absolute copy" onClick={() => copy()}>
              <img src={copyIcon} alt="copy xtz address" />
            </button>
          </div>
        </div>
        <div className="grayHr mtop44"></div>
        <div className="mtop32 panelInfo">
          Or just input the amount and confirm the transaction in your wallet:
        </div>
        <Donate />
      </div>
    </div>
  );
};
