import React from 'react';

import { HelpSection } from './HelpSection';
import { ComebackSection } from './ComebackSection';
import { CashSection } from './CashSection';
import { FaqSection } from './FaqSection';
import { FooterSection } from './FooterSection';

import copyIcon from '../../static/images/copy.svg';
import { Donate } from './HelpSection/Donate';
import { DONATION_ADDRESS } from '../config';
import { copy } from '../helpers/utils/copy';
import { Comunity } from './HelpSection/Comunity';

function App() {
  return (
    <div className="App">
      <HelpSection />
      <ComebackSection />
      <CashSection />
      <FaqSection />
      <FooterSection />
      <div className="rightWrap desktop">
        <div className="rightSide">
          <div className="rightPanel ">
            <h3 className="gray80">Send XTZ to this address</h3>
            <div className="relative">
              <div className="inputWrapper" style={{ marginTop: '16px' }}>
                <input
                  className="input fullwidth inputReadonly"
                  defaultValue={DONATION_ADDRESS}
                  readOnly
                />
                <button onClick={() => copy()} className="absolute copy">
                  <img src={copyIcon} alt="copy xtz address" />
                </button>
              </div>
              <div className="grayHr mtop64"></div>
              <div className="mtop32 panelInfo">
                Or just input the amount and confirm
                <br />
                the transaction in your wallet:
              </div>
              <Donate />
            </div>
          </div>
          <Comunity />
        </div>
      </div>
    </div>
  );
}

export default App;
