import { useCallback } from 'react';
import { AbortedBeaconError } from '@airgap/beacon-sdk';
import { Button, Modal } from '@quipuswap/ui-kit';

import { Wallets } from './content';
import * as s from './WalletModal.module.sass';
import { WalletType } from '../../../helpers/types';
import { useConnectModalsState } from '../../../providers/use-connect-modals-state';
import {
  useConnectWithBeacon,
  useConnectWithTemple,
  TEMPLE_WALLET_NOT_INSTALLED_MESSAGE,
} from '../../../providers/use-dapp';

export const Wallet = ({ id, Icon, label, onClick, disabled = false }) => (
  <Button
    className={s.button}
    innerClassName={s.buttonInner}
    textClassName={s.buttonContent}
    theme="secondary"
    onClick={() => !disabled && onClick(id)}
    disabled={disabled}
  >
    <Icon className={s.icon} />
    <span>{label}</span>
  </Button>
);

export const WalletModal = () => {
  const {
    connectWalletModalOpen,
    closeConnectWalletModal,
    openInstallTempleWalletModal,
  } = useConnectModalsState();
  const { closeAccountInfoModal } = useConnectModalsState();
  const connectWithBeacon = useConnectWithBeacon();
  const connectWithTemple = useConnectWithTemple();

  const handleConnectClick = useCallback(
    async (walletType) => {
      try {
        if (walletType === WalletType.BEACON) {
          await connectWithBeacon(true);
        } else {
          await connectWithTemple(true);
        }

        if (sessionStorage.toDonate) {
          sessionStorage.toDonateFinal = sessionStorage.toDonate;
        }

        closeAccountInfoModal();
        closeConnectWalletModal();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e) {
        if (e.message === TEMPLE_WALLET_NOT_INSTALLED_MESSAGE) {
          openInstallTempleWalletModal();
        } else {
          const authenticationWasRejected =
            e.name === 'NotGrantedTempleWalletError' ||
            e instanceof AbortedBeaconError;
          if (!authenticationWasRejected) {
            // eslint-disable-next-line no-console
            console.error(e);
          }
        }
      }
    },
    [
      closeAccountInfoModal,
      closeConnectWalletModal,
      connectWithBeacon,
      connectWithTemple,
      openInstallTempleWalletModal,
    ]
  );

  return (
    <Modal
      containerClassName={s.modalWrap}
      contentClassName={s.modal}
      cardClassName={s.card}
      title="Connect wallet"
      isOpen={connectWalletModalOpen}
      onRequestClose={closeConnectWalletModal}
    >
      <div className={s.wallets}>
        {Wallets.map(({ id, Icon, label }) => (
          <Wallet
            key={id}
            id={id}
            Icon={Icon}
            label={label}
            onClick={handleConnectClick}
          />
        ))}
      </div>
    </Modal>
  );
};
