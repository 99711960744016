import React from 'react';
import { useState } from 'react';

import copyIcon from '../../static/images/copy.svg';
import twitterBlueIcon from '../../static/images/twitter-blue.svg';

import { DONATION_ADDRESS, TWITTER_URL } from '../config';

const usdEurTabValues = ['USD', 'EUR'];
const usdEurGbpTabValues = ['USD', 'EUR', 'GBP'];
const btcEthTrcErcTabValues = ['BTC', 'ETH', 'TRC', 'ERC'];

const USD_EUR = {
  USD: {
    name: 'USD',
    value: `SWIFT
Company Name
CO "INTERNATIONAL CHARITABLE FOUNDATION "COME BACK ALIVE"

IBAN Code
UA173052990000026009035028620

Name of the Bank
JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE
Bank SWIFT-Code

PBANUA2X

Company address
32 BOHDANA KHMELNYTSKOHO STR., KYIV, 01054, UKRAINE (32 Bogdan Khmelnitsky st., apt. 41, Kyiv, Ukraine)

Purpose of payment
Charitable donation to the Ukrainian military`,
  },
  EUR: {
    name: 'EUR',
    value: `SWIFT
Company Name
CO "INTERNATIONAL CHARITABLE FOUNDATION "COME BACK ALIVE"

IBAN Code
UA093052990000026004025029786

Name of the Bank
JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE
Bank SWIFT-Code

PBANUA2X

Company address
32 BOHDANA KHMELNYTSKOHO STR., KYIV, 01054, UKRAINE (32 Bogdan Khmelnitsky st., apt. 41, Kyiv, Ukraine)

Purpose of payment
Charitable donation to the Ukrainian military`,
  },
};

const USD_EUR_GBP = {
  USD: {
    name: 'USD',
    value: `SWIFT Code NBU: NBUA UA UX
JP MORGAN CHASE BANK, New York
SWIFT Code: CHASUS33
Account: 400807238
383 Madison Avenue, New York, NY 10179, USA
Beneficiary: UA843000010000000047330992708`,
  },
  EUR: {
    name: 'EUR',
    value: `SWIFT Code NBU: NBUA UA UX
DEUTSCHE  BUNDESBANK, Frankfurt
SWIFT Code: MARKDEFF
Account: 5040040066
IBAN DE05504000005040040066
Wilhelm-Epsteinn-Strabe  14, 60431   Frankfurt Am Main,Germany
Beneficiary: UA843000010000000047330992708`,
  },
  GBP: {
    name: 'GBP',
    value: `SWIFT Code NBU: NBUA UA UX
Bank of England, London
SWIFT Code: BKENGB2L
Account: 40000982
Threadneedle Street, London  EC2R 8AH, UK
Beneficiary: UA843000010000000047330992708`,
  },
};

const BTC_ETH_TRC_ERC = {
  BTC: {
    name: 'BTC',
    value: '357a3So9CbsNfBBgFYACGvxxS6tMaDoa1P',
  },
  ETH: {
    name: 'ETH',
    value: '0x165CD37b4C644C2921454429E7F9358d18A45e14',
  },
  TRC: {
    name: 'USDT (TRC20)',
    value: 'TEFccmfQ38cZS1DTZVhsxKVDckA8Y6VfCy',
  },
  ERC: {
    name: 'USDT (ERC20)',
    value: '0x165CD37b4C644C2921454429E7F9358d18A45e14',
  },
};

export const CashSection = () => {
  const copy = (text) => {
    var content = text || DONATION_ADDRESS;
    navigator.clipboard.writeText(content);
  };
  const [usdEurTab, setUsdEurTab] = useState(usdEurTabValues[0]);
  const [usdEurGbpTab, setUsdEurGbpTab] = useState(usdEurGbpTabValues[0]);
  const [btcEthTrcErcTab, setBtcEthTrcErcTab] = useState(
    btcEthTrcErcTabValues[0]
  );
  const [spoileredUsdEur, setSpoileredUsdEur] = useState(true);
  const [spoileredUsdEurGbp, setSpoileredUsdEurGbp] = useState(true);
  return (
    <section>
      <div id="cash" className="container container2">
        <div className="comebackHeader">
          <span className="blue30">Send</span> cash and other crypto
        </div>
        <p className="mtop44 combebackSubheader mw-704">
          MadFish Solutions contacted the foundation and offered them an
          alternative way to support Ukraine with XTZ. The foundation also
          accepts cash and Bitcoin, so you can help them even if you don’t own
          any XTZ. A few more institutions below also accept donations.
        </p>
        <h3 className="mtop48 yellow50 swift">SWIFT</h3>
        <div className="tabWrapper mtop24">
          {Object.keys(USD_EUR).map((x) => (
            <div
              key={x}
              onClick={() => setUsdEurTab(x)}
              className={`tabItem ${x === usdEurTab ? 'activeTab' : ''}`}
            >
              {USD_EUR[x].name}
            </div>
          ))}
        </div>
        <div className="mtop16 tabContent relative">
          <button
            className="absolute copy"
            onClick={() => copy(USD_EUR[usdEurTab].value)}
          >
            <img src={copyIcon} alt="copy swift address" />
          </button>
          <pre className={`${spoileredUsdEur && 'spoilered'}`}>
            {USD_EUR[usdEurTab].value}
          </pre>
          <div
            className="spoiler"
            onClick={() => setSpoileredUsdEur(!spoileredUsdEur)}
          >
            <div className="spoilerText">
              {!spoileredUsdEur ? 'Show less' : 'Show full address'}
            </div>
          </div>
        </div>
        <h3 className="mtop64 yellow50 swift">Bitcoin</h3>
        <div className="mtop16 tabContent relative btc">
          <button
            className="absolute copy"
            onClick={() => copy('bc1qkd5az2ml7dk5j5h672yhxmhmxe9tuf97j39fm6')}
          >
            <img src={copyIcon} alt="copy btc address" />
          </button>
          <pre>bc1qkd5az2ml7dk5j5h672yhxmhmxe9tuf97j39fm6</pre>
        </div>
        <h3 className="mtop64 yellow50 swift">Credit card transfer</h3>
        <div className="cards">
          <div className="mtop16 flex debitCard">
            <a
              href="https://pay.fondy.eu/api/checkout?button=59da139c540533216e39fef311708bed"
              target="_blank"
              rel="noopener noreferrer"
              className="walletButton mediumButton"
            >
              Send by card
            </a>
            <p>(accept Visa, MasterCard, PROSTIR and Google Pay)</p>
          </div>
        </div>
        <h3 className="mtop64 yellow50 swift">
          USD, GBP and EUR to National Bank of Ukraine
        </h3>
        <div className="tabWrapper mtop24">
          {Object.keys(USD_EUR_GBP).map((x) => (
            <div
              key={x}
              onClick={() => setUsdEurGbpTab(x)}
              className={`tabItem ${x === usdEurGbpTab ? 'activeTab' : ''}`}
            >
              {USD_EUR_GBP[x].name}
            </div>
          ))}
        </div>
        <div className="mtop16 tabContent relative">
          <button
            className="absolute copy"
            onClick={() => copy(USD_EUR_GBP[usdEurGbpTab].value)}
          >
            <img src={copyIcon} alt="copy NBU swift address" />
          </button>
          <pre className={`${spoileredUsdEurGbp && 'spoilered'}`}>
            {USD_EUR_GBP[usdEurGbpTab].value}
          </pre>
          {
            <div
              className="spoiler"
              onClick={() => setSpoileredUsdEurGbp(!spoileredUsdEurGbp)}
            >
              <div className="spoilerText">
                {!spoileredUsdEurGbp ? 'Show less' : 'Show full address'}
              </div>
            </div>
          }
        </div>
        <h3 className="mtop64 yellow50 swift">
          Cryptocurrencies via the Ministry
          <br />
          of Digital Transformation of Ukraine
        </h3>
        <div className="tabWrapper mtop24">
          {Object.keys(BTC_ETH_TRC_ERC).map((x) => (
            <div
              key={x}
              onClick={() => setBtcEthTrcErcTab(x)}
              className={`tabItem ${x === btcEthTrcErcTab ? 'activeTab' : ''}`}
            >
              {BTC_ETH_TRC_ERC[x].name}
            </div>
          ))}
        </div>
        <div className="mtop16 tabContent relative btc">
          <button
            className="absolute copy"
            onClick={() => copy(BTC_ETH_TRC_ERC[btcEthTrcErcTab].value)}
          >
            <img src={copyIcon} alt="copy crypto address" />
          </button>

          <pre>{BTC_ETH_TRC_ERC[btcEthTrcErcTab].value}</pre>
        </div>
        <h3 className="mtop64 yellow50 swift">
          Cryptocurrencies and NFTs to #Unchain_Ukraine
        </h3>
        <div className="cards">
          <div className="mtop16 flex charityCard">
            <a
              href="https://unchain.fund/"
              target="_blank"
              rel="noopener noreferrer"
              className="walletButton mediumButton"
            >
              Donate
            </a>
            <p>A charity project created by blockchain activists</p>
          </div>
        </div>
        <div className="grayHr grayMargin mw-704"></div>
        <div className="hashtag hashtagMargin">#TezosStandsWithUkraine</div>
        <div className="embeded mtop50">
          <div
            className="tagembed-container"
            style={{ width: '100%', height: '100%', overflow: 'auto' }}
            className="mw-704"
          >
            <a
              className="twitter-timeline"
              data-tweet-limit="5"
              href={TWITTER_URL}
            >
              Tweets by TezosUkraine
            </a>
          </div>
        </div>
        <h3 className="mtop48 mw-1">
          Be sure to always know what’s going on in Ukraine through Twitter:
        </h3>
        <div className="mtop24 twitterSocial">
          <img src={twitterBlueIcon} alt="twitter" />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/i/lists/1494327296383021062"
            className="link"
          >
            List of reporters on the ground
          </a>
        </div>
        <div className="mtop16 twitterSocial">
          <img src={twitterBlueIcon} alt="twitter" />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/mfa_ukraine"
            className="link"
          >
            Ministry of Foreign Affairs of Ukraine
          </a>
        </div>
        <div className="mtop16 twitterSocial">
          <img src={twitterBlueIcon} alt="twitter" />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/Ukraine"
            className="link"
          >
            Official Twitter account of Ukraine
          </a>
        </div>
      </div>
    </section>
  );
};
