import Layout from "../components/Layout";
import SEO from "../components/Seo";
import App from "../components/App";
import { DAppProvider } from "../providers/use-dapp";
import { WalletWrapper } from "../providers/wallet-wrapper";
import { DonationProgressProvider } from "../providers/use-donation-progress";

const IndexPage = () => (
  <Layout>
    <DAppProvider>
      <WalletWrapper>
        <DonationProgressProvider>
          <SEO />
          <App />
        </DonationProgressProvider>
      </WalletWrapper>
    </DAppProvider>
  </Layout>
);

export default IndexPage;
