import React from 'react';

export const FaqSection = () => (
  <section>
    <div id="faq" className="container">
      <div className="comebackHeader mw-2">
        <span className="blue30">Got questions?</span>{' '}
        <span className="yellow40">We have answers!</span>
      </div>
      <div className="mw-704">
        <h3 className="mtop44">
          Why did we make this site and how does it work?
        </h3>
        <p className="mtop16">
          The Tezos community was asking us how they can help Ukraine in the
          midst of this disaster. We knew that the Come Back Alive foundation
          accepts donations only in cash or BTC. We contacted them and offered
          them an alternative way for the Tezos ecosystem members to support
          Ukraine. We accept donations in XTZ, convert them into BTC, and send
          the funds to the foundation.
        </p>
        <h3 className="mtop44">What is the foundation’s focus?</h3>
        <p className="mtop16">
          The foundation has been procuring munition for Ukraine’s armed forces
          since 2014. Additionally, they organise equipment repairs and run
          training programs for the military.
        </p>
        <h3 className="mtop44">Who is MadFish Solutions?</h3>
        <p className="mtop16">
          It is a Ukrainian team of developers who created Temple Wallet and
          QuipuSwap, which are among the most popular Tezos products.
        </p>
        <h3 className="mtop44">How will the funds be used?</h3>
        <p className="mtop16">
          We will be sending the funds to the foundation Come Back Alive
          converted in BTC and use the remainder to provide personal help to
          individuals or groups of people who help civilians and the military
          all over Ukraine.
        </p>
      </div>
    </div>
  </section>
);
