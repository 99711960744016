import React, { useState } from 'react';
import presidentIcon from '../../../static/images/president.png';
import copyIcon from '../../../static/images/copy.svg';
import { Donate } from './Donate';
import { DONATION_ADDRESS } from '../../config';
import { copy } from '../../helpers/utils/copy';
import { DonationProgress } from './DonationProgress';
import { DonationShape } from './DonationShape';
import { Comunity } from './Comunity';
import { TextHeader } from './TextHeader';

export const Mobile = () => {
  return (
    <div className="container flex firstSection mobile ptop72">
      <TextHeader />
      <div className="mtop50">
        <DonationProgress />
        <div className="mtop16">
          <DonationShape width={648} />
        </div>
      </div>
      <div className="flex mtop40 justify-between">
        <div>
          <div className="quoteContainer">
            <div className="presidentQuote">
              “I need ammunition, not a ride”
            </div>
          </div>
          <div id="send1" className="flex mtop20 mleft30 align-center">
            <img src={presidentIcon} className="presidentImg" alt="president" />
            <div className="flex flex-col mleft16">
              <span className="presidentName">Volodymyr Zelensky</span>
              <span className="presidentPosition">
                The President of Ukraine
              </span>
            </div>
          </div>
        </div>
      </div>
      <a href="#send1" className="walletGradient">
        <div className="walletButton modalButton">Send XTZ</div>
      </a>
      <div className="rightPanel mtop32">
        <h3 className="gray80 fullwidth tacenter">Send XTZ to this address</h3>
        <div className="mtop20 donateInput">
          <input defaultValue={DONATION_ADDRESS} readOnly />
          <button className=" copy" onClick={() => copy()}>
            <img src={copyIcon} alt="copy xtz address" />
          </button>
        </div>
        <div className="grayHr mtop44"></div>
        <div className="mtop32 panelInfo">
          Or just input the amount and confirm the transaction in your wallet:
        </div>
        <Donate />
      </div>
      <Comunity />
    </div>
  );
};
