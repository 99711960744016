import React from "react";
import twitterYellowIcon from "../../static/images/twitter-yellow.svg";
import telegramYellowIcon from "../../static/images/telegram-yellow.svg";
import ukraineIcon from "../../static/images/ukraine.svg";
import { TWITTER_URL } from "../config";

export const FooterSection = () => (
  <section className="footer">
    <div className="container container2">
      <div className="hashtag footerMargin">#TezosStandsWithUkraine</div>
      <div className="footerSocials">
        <a
          href={TWITTER_URL}
          target="_blank"
          rel="noreferrer"
          className="footerSocialItem"
        >
          <div>Join us on Twitter</div>
          <img src={twitterYellowIcon} alt="twitter" />
        </a>
      </div>
      <img src={ukraineIcon} className="ukraine" alt="ukraine" />
    </div>
  </section>
);
