import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import tezos from '../../static/images/tezos.svg';
import cross from '../../static/images/cross.svg';
import madfish from '../../static/images/madfish.svg';
import twitter from '../../static/images/twitter.svg';
import telegram from '../../static/images/telegram.svg';
import burger from '../../static/images/burger.svg';
import closeIcon from '../../static/images/close.svg';
import { TELEGRAM_URL, TWITTER_URL } from '../config';

const Header = ({ siteTitle }) => {
  const [open, setOpen] = useState(false);
  return (
    <header>
      <div className={['superheader', open && 'superunfold'].join(' ')}>
        <div className={[`header`, open && 'header-fixed'].join(' ')}>
          <div className="flex">
            <div className="headerItem headerLogos">
              <Link
                to="/"
                title={siteTitle}
                className="flex items-center"
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  color: '#4a5568',
                }}
              >
                <img src={madfish} alt="Mad.fish" className="logo2" />
              </Link>
            </div>
            <div className="headerItem headerNavigation desktopAndLaptop">
              <Link to="#comeback">Why Come Back Alive</Link>
              <Link to="#cash">Send cash</Link>
              <Link to="#faq">FAQ</Link>
            </div>
          </div>
          <div className="headerItem headerSocials desktopAndLaptop">
            <a href={TWITTER_URL} target="_blank" rel="noreferrer">
              <img src={twitter} alt="twitter" />
            </a>
          </div>
          <div className="headerItem mobileHeaderItem burgerHeader">
            {!open && (
              <img src={burger} alt="burger" onClick={() => setOpen(!open)} />
            )}
            {open && (
              <img src={closeIcon} alt="close" onClick={() => setOpen(!open)} />
            )}
          </div>
        </div>
        {open && (
          <div className="tablet-header header">
            <div className="headerItem headerNavigation">
              <Link onClick={() => setOpen(false)} to="#comeback">
                Why Come Back Alive
              </Link>
              <Link onClick={() => setOpen(false)} to="#cash">
                Send cash
              </Link>
              <Link onClick={() => setOpen(false)} to="#faq">
                FAQ
              </Link>
            </div>
            <div className="headerItem headerSocials">
              <a href={TWITTER_URL} target="_blank" rel="noreferrer">
                <img src={twitter} alt="twitter" />
              </a>
            </div>
          </div>
        )}
      </div>
      {open && (
        <div className="mobile-header header">
          <div className="headerItem headerNavigation">
            <Link onClick={() => setOpen(false)} to="#comeback">
              Why Come Back Alive
            </Link>
            <Link onClick={() => setOpen(false)} to="#cash">
              Send cash
            </Link>
            <Link onClick={() => setOpen(false)} to="#faq">
              FAQ
            </Link>
          </div>
          <div className="headerItem headerSocials">
            <a href={TWITTER_URL} target="_blank" rel="noreferrer">
              <img src={twitter} alt="twitter" />
            </a>
          </div>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
