export const APP_NAME = "Save Ukraine";
export const SAVED_LISTS_KEY = "savedCustomLists";
export const SAVED_TOKENS_KEY = "savedCustomTokens";
export const SAVED_BAKERS_KEY = "savedCustomBakers";
export const SAVED_TERMS_KEY = "savedTerms";
export const SAVED_ANALYTICS_KEY = "savedAnalytics";
export const BASE_URL = "";
export const LAST_USED_CONNECTION_KEY = "lastUsedConnection";
export const LAST_USED_ACCOUNT_KEY = "lastUsedAccount";
export const NETWORK_ID_KEY = "networkId";

export const MAINNET_NETWORK = {
  id: "mainnet",
  connectType: "default",
  name: "Tezos Mainnet",
  type: "main",
  rpcBaseURL: "https://mainnet-node.madfish.solutions/",
  description: "Tezos mainnet",
  disabled: false,
};

export const ALL_NETWORKS = [MAINNET_NETWORK];

export const DEFAULT_NETWORK = MAINNET_NETWORK;
