import React from 'react';
import { OVERALL_REQUIRED_DONATIONS } from '../../config';
import { numberWithSpaces } from '../../helpers/utils/numberWithSpaces';
import { useDonationProgress } from '../../providers/use-donation-progress';

export const DonationProgress = () => {
  const { donated } = useDonationProgress();
  return (
    <div className="donation">
      The community has raised&nbsp;
      <strong className="donateCurrent blue30">
        {numberWithSpaces((+donated || 0).toFixed(2))} XTZ
      </strong>{' '}
      <span className="donateTarget">
        / {numberWithSpaces(OVERALL_REQUIRED_DONATIONS)} XTZ
      </span>
    </div>
  );
};
