import React, { useRef, useState, useEffect } from 'react';
import { useConnectModalsState } from '../../providers/use-connect-modals-state';
import {
  useAccountPkh,
  useReady,
  useTezos,
  useDisconnect,
} from '../../providers/use-dapp';
import arrow from '../../../static/images/arrow.svg';

import { DONATION_ADDRESS } from '../../config';

export const Donate = () => {
  const tezos = useTezos();
  const ready = useReady();
  const accountPkh = useAccountPkh();
  const disconnect = useDisconnect();
  const { openConnectWalletModal } = useConnectModalsState();

  const [custom, setCustom] = useState(false);
  const [value, setValue] = useState(0);

  const disconnectingRef = useRef(false);

  const handleDonate = async (summ) => {
    const trueSumm = parseInt(summ);
    if (ready && accountPkh) {
      try {
        await tezos.wallet
          .transfer({ to: DONATION_ADDRESS, amount: trueSumm })
          .send();
        alert('Success! Waiting for confirm');
      } catch (err) {
        console.error(err.message);
      }
    } else {
      sessionStorage.toDonate = summ;
      openConnectWalletModal();
    }
  };

  const handleCustomDonate = () => {
    setCustom(!custom);
  };

  const handleDisconnect = async () => {
    if (disconnectingRef.current) return;
    disconnectingRef.current = true;

    try {
      await disconnect();
    } catch (err) {
      alert(err.message);
    }

    disconnectingRef.current = false;
  };

  useEffect(() => {
    let t;

    const loop = () => {
      if (sessionStorage.toDonateFinal && ready && accountPkh) {
        handleDonate(sessionStorage.toDonateFinal);
        sessionStorage.removeItem('toDonateFinal');
      }

      t = setTimeout(loop, 1_000);
    };

    loop();

    return () => {
      clearTimeout(t);
    };
  }, [ready, accountPkh, handleDonate]);

  return (
    <>
      <div
        className={`mtop16 flex flexwrap walletButtonContainer ${
          custom && 'custom'
        }`}
      >
        {custom && (
          <div className="customDonate">
            <input
              className="input"
              value={value}
              type="number"
              min={1}
              onChange={(e) => setValue(e.target.value)}
            />
            <div
              onClick={() => handleDonate(value)}
              className="walletButton fullwidth mtop16 flex justify-between relative donateBtn"
            >
              Donate
              <img src={arrow} className="absolute arrow" alt="arrow" />
            </div>
          </div>
        )}
        {!custom && (
          <>
            <div onClick={() => handleDonate(50)} className="walletButton">
              50 XTZ
            </div>
            <div onClick={() => handleDonate(250)} className="walletButton">
              250 XTZ
            </div>
            <div onClick={() => handleDonate(500)} className="walletButton">
              500 XTZ
            </div>
            <div onClick={() => handleDonate(1000)} className="walletButton">
              1000 XTZ
            </div>
            <div onClick={() => handleDonate(5000)} className="walletButton">
              5000 XTZ
            </div>
          </>
        )}
        {!custom && (
          <div onClick={() => handleCustomDonate()} className="walletButton">
            Other
          </div>
        )}
      </div>

      {accountPkh && (
        <>
          <div className="grayHr mtop44" />

          <div
            style={{
              marginTop: 32,
            }}
          >
            <div className="flex align-center">
              <div
                style={{
                  color: '#424a4f',
                  fontSize: 18,
                  lineHeight: '24px',
                }}
              >
                Connected wallet:
              </div>

              <div className="flex-1"></div>

              <div
                style={{
                  background: '#f5f8fa',
                  padding: '8px 12px',
                  fontSize: 15,
                }}
              >
                {getAddressPreview(accountPkh)}
              </div>
            </div>

            <div className="flex align-center" style={{ marginTop: 12 }}>
              <div className="flex-1" />

              <button
                type="button"
                style={{
                  textDecoration: 'underline',
                  color: '#2f6cf6',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={handleDisconnect}
              >
                Disconnect
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

function getAddressPreview(hash) {
  const ln = hash.length;
  return `${hash.slice(0, 7)}...${hash.slice(ln - 4, ln)}`;
}
