import React from 'react';
import presidentIcon from '../../../static/images/president.png';

import { DonationProgress } from './DonationProgress';
import { DonationShape } from './DonationShape';
import { TextHeader } from './TextHeader';

export const Desktop = () => (
  <div className="container flex firstSection desktop">
    <div className="leftSide">
      <TextHeader />
      <div className="mtop64">
        <DonationProgress />
        <div className="mtop16">
          <DonationShape />
        </div>
      </div>
      <div className="mtop80 quoteContainer">
        <div className="presidentQuote">“I need ammunition, not a ride”</div>
      </div>
      <div className="flex mtop20">
        <img src={presidentIcon} className="presidentImg" alt="president" />
        <div className="flex flex-col mleft16">
          <span className="presidentName">Volodymyr Zelensky</span>
          <span className="presidentPosition">The President of Ukraine</span>
        </div>
      </div>
    </div>
  </div>
);
