import React from "react";
import { OVERALL_REQUIRED_DONATIONS } from "../../config";
import { useDonationProgress } from "../../providers/use-donation-progress";

export const DonationShape = ({ width = 704 }) => {
  const { donated } = useDonationProgress();
  return (
    <svg
      className="progress"
      viewBox={`0 0 ${width || 0} 8`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width || "0"} height="8" rx="4" fill="#E8EDF0" />
      <rect
        width={(donated / OVERALL_REQUIRED_DONATIONS) * width || 0}
        height="8"
        fill="#F3D32F"
      />
    </svg>
  );
};
