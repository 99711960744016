import React from "react";
import { Desktop } from "./Desktop";
import { Laptop } from "./Laptop";
import { Mobile } from "./Mobile";
import { Tablet } from "./Tablet";

export const HelpSection = () => {
  return (
    <section>
      <Desktop />
      <Laptop />
      <Tablet />
      <Mobile />
    </section>
  );
};
