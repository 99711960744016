export class ReadOnlySigner {
  constructor(pkh, pk) {
    this.pkh = pkh;
    this.pk = pk;
  }

  async publicKeyHash() {
    console.info(this.pkh);
    return this.pkh;
  }

  async publicKey() {
    return this.pk;
  }

  async secretKey() {
    throw new Error("Secret key cannot be exposed");
  }

  async sign() {
    throw new Error("Cannot sign");
  }
}
