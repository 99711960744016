import React, { useState, useEffect } from 'react';

export const Comunity = ({ className }) => {
  return (
    <div className={['rightPanelInfo', className].join(' ')}>
      Initiated by Tezos community
      <br />
      Brought by{' '}
      <a
        href="https://www.madfish.solutions/"
        target="_blank"
        rel="noopener noreferrer"
        className="comunityLink"
      >
        MadFish Solutions
      </a>
      {/* <br />
      Supported by the{" "}
      <a
        href="https://thedigital.gov.ua/"
        target="_blank"
        rel="noopener noreferrer"
        className="comunityLink"
      >
        Ministry of Digital Transformation of Ukraine
      </a> */}
    </div>
  );
};
