import { Beacon, Temple } from "@quipuswap/ui-kit";
import { WalletType } from "../../../helpers/types";

export const Wallets = [
  {
    id: WalletType.TEMPLE,
    Icon: Temple,
    label: "Temple Wallet",
  },
  {
    id: WalletType.BEACON,
    Icon: Beacon,
    label: "Beacon",
  },
];
