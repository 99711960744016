import React from "react";

export const TextHeader = () => (
  <>
    <div className="helpHeader">
      Help Ukraine with XTZ,
      <br />
      <span className="blue30">don’t leave</span> us alone
      <br />
      <span className="yellow40">with the enemy</span>
    </div>
    <div className="helpSubheader mw-554">
      Donate XTZ to Ukraine via the Come Back Alive foundation. The people will
      do the rest
    </div>
  </>
);
