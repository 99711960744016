import React from 'react';

export const ComebackSection = () => {
  return (
    <section>
      <div id="comeback" className="container">
        <div className="comebackHeader">
          <span className="blue30">Come Back Alive</span> is the biggest
          <br />
          Ukrainian foundation helping
          <br />
          Ukraine resist invasion
        </div>
        <p className="mtop44 combebackSubheader">
          The foundation has been issuing reports on the used funds as of 2014
          when Russia first invaded Ukraine. Ukrainians have already donated
          more than $15 million to the foundation. The foundation has executed
          over 10,000 orders for the benefit of the Ukrainian armed forces.
          <br />
          <br />
          The foundation has already suffered from centralised services: it
          started a Patreon page and raised nearly $500,000 a month but the
          administration suspended the fundraising campaign.
        </p>
      </div>
    </section>
  );
};
